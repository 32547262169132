import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout/index.js";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Wide from "../components-styled/Wide";
import PictureSet from "../components/PictureSet";
import imgSet04 from "../imgsets/imgset04";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Visual Business Planner" keywords={[]} mdxType="SEO" />
    <Wide mdxType="Wide">
      <PictureSet imgSet={imgSet04} mdxType="PictureSet" />
    </Wide>
    <h1>{`Visual Business Planner`}</h1>
    <p>{`Business Plans — Geschäftspläne sind nicht leicht zu erstellen. Man muss Preise, Umsatz, Kosten und Investitionen sowie die Finanzierung planen. Dabei muss man die Liquidität im Blick behalten sonst gerät man schnell in die Insolvenz.`}</p>
    <p>{`Dreh und Angelpunkt sind die Kunden. Diese muss man gewinnen. In der Regel braucht man dafür eine Menge Marketing — das ist immer aufwändig und oft teuer. Wenn man die Kunden dann gewonnen hat, muss man sie halten. In der Regel möchten man mit seinen Kunden nicht nur ein Einmalgeschäft abschließen, sondern möchte, dass sie wiederkommen. Eventuell kann man ihnen sogar ein Abonnement anbieten.`}</p>
    <p>{`All das in einem Spreadsheet abzubilden, ist wirklich nicht so einfach. Insbesondere, wenn man mit den Parametern experimentieren und den Business Plan anschließend mit Dritten (Berater, VCs, Fördermittelgeber, Banken, etc.) diskutieren möchte. Dafür braucht man Interaktivität und eine grafische Unterstützung.`}</p>
    <p>{`Glücklicherweise hilft euch dazu der hgnc Business Planner. Probiert ihn aus und sammelt eure Erfahrungen:`}</p>
    <p><a parentName="p" {...{
        "href": "https://business-planner.online/?at=hgnc",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://business-planner.online`}</a></p>
    <p>{`Der Visual Business Planner ist eine Web-App, mit der ihr in kurzer Zeit auch erweiterte Geschäftspläne erstellen könnt. Dadurch kann man Produktideen und Geschäftsmodelle validieren. Der Visual Business Planner arbeitet interaktiv, beseitigt die Komplexität durch die Online-Nutzung und zeigt alle Ergebnisse zur einfachen Interpretation grafisch an.`}</p>
    <p><a parentName="p" {...{
        "href": "https://business-planner.online/?at=hgnc",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1049px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.29166666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wgARCAASABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAQCBQn/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgH/2gAMAwEAAhADEAAAAe5UmVjN4hElgmkK/8QAGxAAAgMBAQEAAAAAAAAAAAAAAgMBBBMSFDX/2gAIAQEAAQUCfNhi1UIr2qrGMUWPlL6lOOV8BlABomIEf//EABoRAAICAwAAAAAAAAAAAAAAAABBAfBRkaH/2gAIAQMBAT8B32orMVkH/8QAHREAAQQCAwAAAAAAAAAAAAAAARARQWEhMVGx8P/aAAgBAgEBPwG5k8gaFs5xZtPdJ//EACQQAAICAgEDBQEBAAAAAAAAAAECAxEEEiEAExQFIiMyQTNR/9oACAEBAAY/Asx1GSdZCkeKr4usoRV1KvJD8Ic2ziVmZDzwCq9RiDvR3A5acDHBWnSojEYSxD7OxkHEbKFo72tyxtGyySx8sJC6RyMqSkoiKDKgDsmvxsTHzrfXqOseKV8qXvg5CCEt7NzmPZ0lrXvKRt/P86xiYsffwMn3PkgeoKO7jbLFCDT4d6+VJfGR4nDfZW0h0HfnJEWQsiljK27FrHyM1mYfk29ljbGYarTyguNRTE3Zb/TwOT1DLovdWJUEmo3CMLZA/wBgrEAlbokC+iFAUbseOOT+8df/xAAaEAEBAAMBAQAAAAAAAAAAAAABEQAhMUFh/9oACAEBAAE/IUCclpKrqhQoxk5DXNRToyiGhwUDYB0GQRotFJGeOBQjGfshDY7AvItugGEGIx8+MWLKBJCA1dqZkfS5waRI2yiwvDHuVLrrEISQYBQxzAQEFKxBV69c/9oADAMBAAIAAwAAABCjD73/xAAfEQEAAQMEAwAAAAAAAAAAAAABEQAhMVFhgbFxofH/2gAIAQMBAT8QWQGQMhEBYlDAoBS9iZipfps159aNGePasDwdV//EABoRAQEAAgMAAAAAAAAAAAAAAAERABBBcbH/2gAIAQIBAT8QAFACAgVCoyhKJQoBazg7fNP/xAAYEAEBAQEBAAAAAAAAAAAAAAABEQAhMf/aAAgBAQABPxCzZMBWBbu2Wel1s6VvphMUNWBO4B3C4uNNiVS6itHERGX9gEOmIctIn7ATkN3sqVMyjRpTFMRFkYC/lS9meBt8FlkTQuiqQmME7CoE7mKdVd//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "BPO1-Landing.jpg",
            "title": "BPO1-Landing.jpg",
            "src": "/static/a88fa076525dba6b39277cead30f7f6d/e1613/hgncBusinessPlanner_Landing_v1.3.3-rc.jpg",
            "srcSet": ["/static/a88fa076525dba6b39277cead30f7f6d/a3e66/hgncBusinessPlanner_Landing_v1.3.3-rc.jpg 480w", "/static/a88fa076525dba6b39277cead30f7f6d/fb816/hgncBusinessPlanner_Landing_v1.3.3-rc.jpg 960w", "/static/a88fa076525dba6b39277cead30f7f6d/e1613/hgncBusinessPlanner_Landing_v1.3.3-rc.jpg 1049w"],
            "sizes": "(max-width: 1049px) 100vw, 1049px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <p>{`Gründer von Startups stehen normalerweise vor der Herausforderung, den Break-Even-Punkt oder die zukünftigen Gewinne ihres Unternehmens, Projekts oder Startups planen zu müssen. Dafür verwenden sie in der Regel Excel. Sie legen einen Preis für ihr Produkt fest und versuchen, Schätzungen über den Umsatz und dann über die Kosten vorzunehmen. In vielen Fällen bleiben sie jedoch stecken, wenn eine detailliertere Planung erforderlich wird. Und sehr schnell wird die Excel-Tabelle sehr komplex und schwierig zu handhaben, insbesondere wenn es darum geht, die Entwicklung von Kundenstämmen, Abonnementmodellen, variablen Kosten, Marketingkosten und -ergebnissen oder Netzwerkeffekten zu planen.`}</p>
    <p><a parentName="p" {...{
        "href": "https://business-planner.online/?at=hgnc",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1000px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgn/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB3RVevEjQv//EABsQAAICAwEAAAAAAAAAAAAAAAECAxIAERMy/9oACAEBAAEFAudY3BRYfDauwVsjAr//xAAbEQABBAMAAAAAAAAAAAAAAAAhAAExUQIR8f/aAAgBAwEBPwHAuYap0LCeeL//xAAdEQEAAQMFAAAAAAAAAAAAAAABIQAC8CJBcZLR/9oACAECAQE/AbtIISx2M93o4yK//8QAJBAAAgEDAwMFAAAAAAAAAAAAAQIREiExAAMiEEFhE3Gh0vD/2gAIAQEABj8CUJQrM6V+lOyKSebCJ5qMSTPfwIFNO6os1m22PJ2ZcmADm8xF51ZO+SgBPnWL+yfSfnSyJ4j9jp//xAAdEAEBAAIDAQEBAAAAAAAAAAABESExAEFRYYHh/9oACAEBAAE/IRHJxk50D7GpiLw3q4bFDn2FGLdATsFeUW9Wkz4beZWgWJ/Q/W/eEDaSoynna5pN64SAgKTHz5z/2gAMAwEAAgADAAAAEGjP/8QAGREBAQEBAQEAAAAAAAAAAAAAASERMQBR/9oACAEDAQE/EFipVHAQeNupNE3o8901arTVRuT7CVDBn//EABwRAQEAAgIDAAAAAAAAAAAAAAERACExUUGB8P/aAAgBAgEBPxCJEFVJUWAaqq78srCYaGjvj26v0z//xAAZEAEBAQEBAQAAAAAAAAAAAAABESExAFH/2gAIAQEAAT8QLBtGZcyeWklbwjWGCFYhmNUCPH021ZpBBJAIVAgC0EAQQDe6uzVhV1aw7RFDjiYK00RtJziDBAYc12GHDPf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "BPO1.jpg",
            "title": "BPO1.jpg",
            "src": "/static/5b5d3571713e6fb94d681205ee58c269/0a251/Business-Planner-hgnc_v1.1.0-rc.de.jpg",
            "srcSet": ["/static/5b5d3571713e6fb94d681205ee58c269/a3e66/Business-Planner-hgnc_v1.1.0-rc.de.jpg 480w", "/static/5b5d3571713e6fb94d681205ee58c269/fb816/Business-Planner-hgnc_v1.1.0-rc.de.jpg 960w", "/static/5b5d3571713e6fb94d681205ee58c269/0a251/Business-Planner-hgnc_v1.1.0-rc.de.jpg 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <p>{`Es gibt einige alternative Businessplan-Lösungen. Einige, wenn nicht die meisten, leiden unter dem wesentlichen Nachteil, dass sie versuchen, einzelne Positionen aus der Bilanz oder der Gewinn- und Verlustrechnung direkt zu planen. Dieser Ansatz ist jedoch ungünstig. Die Finanzberichterstattung und ihre Struktur ist das Ergebnis am Ende einer Periode und nicht der Ausgangspunkt eines Unternehmens. Ausgangspunkt aller Geschäftsideen oder Projekte ist der Kunde, dem ein Produkt angeboten wird. Von dort aus müssen sich dann die Zahlen entwickeln — und genau das macht der Visual Business Planner.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      